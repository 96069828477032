export const OPTIMIZELY_FEATURE_FLAGS = {
  ONBOARDING_CHECKLIST: "onboarding_checklist",
  WEB_API_CONSENT_TO_MOONBASE_ENTITY_CHANGE: "web_api_consent_to_moonbase_entity_change",
  WEB_CASH_DEPOSIT_FLOW: "web_cash_deposit_flow",
  WEB_GOOGLE_GEOCODING_API: "web_google_geocoding_api",
  WAITLIST_CARD: "web_waitlist_card",
  WEB_POLLING_INTERVALS: "web_polling_intervals",
  WEB_SNAPCHAT_ANALYTICS_CREDIT_CARD: "web_snapchat_analytics_credit_card",
  WEB_NEURO_ID_CREDIT_CARD: "web_neuro_id_credit_card",
  WEB_LUNA_UST_MARKET_CLOSED: "web_luna_ust_market_closed",
  PAYPAL_ENABLED: "paypal_enabled",
  WEB_GOOGLE_ANALYTICS: "web_google_analytics",
  WEB_BRAZE_SDK: "web_braze_sdk",
  WEB_ADWORDS: "web_adwords",
  WEB_ACTIVATED_GTM: "web_gtm",
  WEB_SNAPCHAT: "web_snapchat",
  WEB_PLAID_IDV_COUNTRIES: "web_plaid_idv_countries",
  WEB_COLOMBIA_KYC_ENABLED: "web_colombia_kyc_enabled",
  WEB_DOCUMENT_SETTINGS_PAGE: "web_document_settings_page",
  WEB_PENDING_REDEMPTIONS: "web_pending_redemptions",
  WEB_GOOGLE_ADDRESS_AUTOCOMPLETE: "web_google_address_autocomplete",
  WEB_FIRST_TIME_NEW_ADDRESS_WITHDRAW: "web_first_time_new_address_withdraw",
  WEB_FIRST_TIME_NEW_ADDRESS_WITHDRAW_UK: "web_first_time_new_address_withdraw_uk",
  EU_TRAVEL_RULE_EMPLOYEE_BYPASS: "eu_travel_rule_employee_bypass",
  WEB_CREDIT_CARD_REFERRAL: "web_credit_card_referral",
  WEB_CREDIT_CARD_REFERRAL_PHASE_2: "web_credit_card_referral_phase_2",
  WEB_CREDIT_CARD_MERCHANT_REWARD_OFFERS: "web_credit_card_merchant_reward_offers",
  WEB_CREDIT_CARD_PREFERRED_NAME: "web_credit_card_preferred_name",
  WEB_CREDIT_CARD_AVAILABLE_CREDIT: "web_credit_card_available_credit",
  WEB_CREDIT_CARD_REWARDS_DISPUTE_QA: "web_credit_card_rewards_dispute_qa",
  WEB_CREDIT_CARD_CHANGE_DUE_DATE: "web_credit_card_change_due_date",
  WEB_CREDIT_CARD_LINE_INCREASE: "web_credit_card_line_increase",
  WEB_CREDIT_CARD_DOWNLOAD_TRANSACTIONS: "web_credit_card_download_transactions",
  WEB_CREDIT_CARD_AUTH_USERS: "web_credit_card_auth_users",
  WEB_CREDIT_CARD_AUTH_USERS_INVITE: "web_credit_card_auth_users_invite",
  WEB_CREDIT_CARD_INTRO_PROMO: "web_credit_card_intro_promo",
  WEB_CREDIT_CARD_SOCURE: "web_credit_card_socure",
  WEB_CREDIT_CARD_APP_REDESIGN_PHASE_1: "web_credit_card_app_redesign_phase_1",
  WEB_CREDIT_CARD_APP_REDESIGN_PHASE_2: "web_credit_card_app_redesign_phase_2",
  WEB_CREDIT_CARD_ORANGE: "web_credit_card_orange",
  WEB_CREDIT_CARD_CLI: "web_credit_card_cli",
  WEB_CREDIT_CARD_SMS_2FA: "web_credit_card_sms_2fa",
  SHOW_ACTIVETRADER_THEME_TOGGLE: "show_activetrader_theme_toggle",
  WEB_EARN_ASSET_RESTRICTIONS: "web_earn_asset_restrictions",
  WEB_ZENDESK_LIVE_CHAT: "zendesk_live_chat",
  WEB_ETHEREUM_WITHDRAWAL_FEES: "web_ethereum_withdrawal_fees",
  WEB_SHOW_TIERED_RATES_DETAILS_IN_BUY_FLOW: "web_show_tiered_rates_details_in_buy_flow",
  WEB_HIDE_TRANSFER_TO_CUSTODY_FEE: "web_hide_transfer_to_custody_fee",
  GIACT_MANUAL_ADD_BANK_ACCOUNT: "giact_manual_add_bank_account",
  WEB_ONFIDO_FORCE_CROSS_DEVICE: "web_onfido_force_cross_device",
  WEB_ONFIDO_ENABLE_ISSUING_COUNTRY_SELECTION: "web_onfido_enable_issuing_country_selection",
  WEB_DISPLAY_OVER_WITHDRAWAL_LIMIT_WARNING: "web_display_over_withdrawal_limit_warning",
  WEB_BUY_SELL_CONVERT_V3: "web_buy_sell_convert_v2",
  WEB_ENABLE_STAKING: "web_enable_staking",
  SPANISH_LOCALIZATION: "spanish_localization_web",
  PORTUGUESE_LOCALIZATION: "portuguese_localization_web",
  ITALIAN_LOCALIZATION: "italian_localization_web",
  TURKISH_LOCALIZATION: "turkish_localization_web",
  FRENCH_LOCALIZATION: "french_localization_web",
  ADVANCED_ACH_DECISIONS_ANIMATIONS: "advanced_account_ach_decisions_animations",
  ADVANCED_ACH_BLOCKLIST: "advanced_account_ach_blocklist",
  WEB_ENABLE_EEA_LAUNCH_GROUP_1: "enable_eea_launch_group_1",
  SETTLEMENT_NAV_LINK: "settlement_nav_link",
  WEB_DATADOG: "web_datadog",
  WEB_DATADOG_LOGGING: "web_datadog_logging",
  WEB_BILLING_SETTINGS_PAGE: "web_billing_settings_page",
  WEB_CA_CCPA_ANALYTICS_SUPPRESSED: "web_ca_ccpa_analytics_suppressed",
  WEB_WIRE_WITHDRAW_FEES: "web_wire_withdrawal_fees",
  WEB_ENABLE_GROW_COLOR_SIDEBAR_CARDS: "web_enable_grow_color_sidebar_card",
  SINGAPORE_MAS_TRANSFER_CONTROL_WEB: "singapore_mas_transfer_control_web",
  GROW_ENABLE_SIDEBAR_BUY_MODULE: "grow_enable_sidebar_buy_module",
  WEB_HIDE_DUST_ON_PORTFOLIO: "web_hide_dust_on_portfolio",
  WEB_HIDE_BALANCES_ON_HOME: "web_hide_balances_on_home",
  APPROVED_ADDRESS_LIST_V2: "approved_address_list_v2",
  WEB_EXPOSURE_LOGGING: "web_exposure_logging",
  WEB_ONFIDO_SELFIE_VERIFICATION: "web_onfido_selfie_verification",
  WEB_ONFIDO_SELFIE_FORCE_X_DEVICE: "web_onfido_selfie_stepup_force_x_device",
  MPC_CUSTODY: "mpc_custody",
  WEB_ONBOARDING_FINANCIAL_INFO: "web_onboarding_financial_info",
  WEB_PAYMENTS_CHECKOUT: "web_checkout",
  MPC_CUSTODY_PHASE_2: "mpc_custody_phase_2",
  WEB_TRANSACTION_HISTORY_V2: "web_transaction_history_v2",
  WEB_CBIT: "web_cbit_transfers",
  WEB_REWARDS_REFERRALS_V2: "web_rewards_referrals",
  BACKEND_COMMISSION_REFERRALS: "backend_commission_referrals",
  WEB_PORTFOLIO_CONSOLIDATION: "web_portfolio_consolidation",
  WEB_ENABLE_ETH_ASSET_ACTIVATION_QUEUE_DISPLAY: "web_enable_eth_asset_activation_queue_display",
  WEB_ENABLE_GROW_ASSET_CAROUSEL: "web_enable_grow_asset_carousel",
  WEB_TAX_UPDATES: "web_tax_updates",
  WEB_TAX_LATEST_1099_TO_SHOW: "web_tax_latest_1099_to_show",
  WEB_TAX_COST_BASIS_BANNER_OVERRIDE: "web_tax_cost_basis_banner_override",
  WEB_ENABLE_GUSD_AIRDROP_REWARDS: "web_enable_gusd_airdrop_rewards",
  WEB_CROSS_COLLATERAL_UI: "web_cross_collateral_ui",
  NETHERLANDS_OFFBOARD: "netherlands_offboard",
  WEB_ENABLE_GUSD_BANKING_PARTNER_MESSAGE: "web_enable_gusd_banking_partner_message",
  WEB_UK_OUTBOUND_TRAVEL_RULE: "web_uk_outbound_travel_rules",
  WEB_ENABLE_KROLL_VOTE: "web_enable_kroll_vote",
  WEB_GUSD_REGULATORY_MESSAGING: "web_enable_gusd_regulatory_messaging",
  UK_FINANCIAL_PROMOTIONS: "uk_financial_promotions",
  ADD_PAYMENTS_REVAMP: "add_payments_revamp",
  ADD_PAYMENTS_REVAMP_GLOBAL: "add_payments_revamp_global",
  WEB_PERMANENT_FEEDBACK: "web_permanent_feedback",
  WEB_COINGECKO_ALL_TIME_DATA: "exchange_coingecko_all_time_data",
  COINGECKO_FOR_MISSING_CHART_DATA: "mobile_coingecko_for_missing_chart_data",
  COINGECKO_TOKEN_MAP: "coingecko_token_map",
  NEW_API_KEY_CREATION_FLOW: "new_api_key_creation_flow",
  WEB_TRANSACTION_HISTORY_REVAMP: "web_transaction_history_revamp",
  WEB_PORTFOLIO_DERIVATIVES_POSITIONS: "web_portfolio_derivatives_positions",
  WEB_GAIN_LOSS_ENABLED: "web_enable_gain_loss",
  WEB_IS_INSTITUTIONAL_XFERS_ENABLED: "is_institutional_xfers_enabled",
  WEB_EARN_DISTRIBUTION_MATERIALS: "web_earn_distribution_materials",
  WEB_EARN_DISTRIBUTIONS_HOMEPAGE_BANNER: "web_earn_distributions_homepage_banner",
  WEB_EARN_DISTRIBUTIONS_BANNER_NAVIGATION: "web_earn_distributions_banner_navigation",
  WEB_EARN_DISTRIBUTIONS_DELISTED_ASSET_SUPPORT: "web_earn_distributions_delisted_asset_support",
  WEB_EARN_DISTRIBUTIONS_CLAIM_CARD_COPY: "web_earn_distributions_claim_card_copy",
  WEB_BANK_FRICK_ENABLED: "web_enable_bank_frick",
  WEB_RETAIL_CURRENCY_SWITCHER: "web_retail_currency_switcher",
  WEB_ACH_DISABLED: "web_ach_disabled",
  SHOW_USER_MANAGEMENT_APPROVAL_ACTIONS: "show_user_management_approval_actions",
  WEB_DEPOSIT_CRYPTO_MODAL: "web_deposit_crypto_modal",
  WEB_PROTAIL: "web_protail",
  WEB_SELFIE_STEPUP_MOTION_VARIANT: "web_selfie_stepup_motion_variant",
  WEB_PERPS_DIRECT_FUNDING: "web_perps_direct_funding",
  WEB_WELCOME_TO_ACTIVE_TRADER_MODAL: "web_welcome_to_active_trader_modal",
  WEB_DERIVATIVES_TRADING_WELCOME_MODAL: "web_derivatives_trading_welcome_modal",
  WEB_INSTITUTIONAL_DEBIT_CARD_DISABLED: "web_institutional_debit_card_disabled",
  WEB_INSTITUTIONAL_PAYPAL_DISABLED: "web_institutional_paypal_disabled",
  WEB_CASH_WITHDRAW_FLOW: "web_cash_withdraw_flow",
  WEB_PERPS_DEBIT_CARD_DIRECT_FUNDING: "web_perps_debit_card_direct_funding",
  WEB_KYC_REFRESH_UPDATE: "web_kyc_refresh_update",
  WEB_POTENTIAL_WITHDRAWAL_DELAYS_BANNER: "web_potential_withdrawal_delays_banner",
  WEB_DEGRADED_SERVICE_BANNER: "web_degraded_service_banner",
  WEB_PERMANENT_CWB: "web_permanent_cwb",
  WEB_WAF_CHALLENGE: "web_waf_challenge",
  WEB_AM_DEPTH_CHART: "am_depth_chart",
  WEB_SHOW_NEW_COPYRIGHT: "web_show_new_copyright",
  WEB_ACTIVETRADER_LEGACY_CHARTS: "activetrader_legacy_charts",
  WEB_CRYPTO_DEPOSIT_MODAL_FLOW: "web_crypto_deposit_modal_flow",
  WEB_CRYPTO_WITHDRAW_MODAL_FLOW: "web_crypto_withdraw_modal_flow",
  WEB_CUSTODY_MAX_WITHDRAWAL: "custody_max_withdrawal",
  WEB_CRYPTO_SHOW_SOLANA_WARNING: "solana_deposit_minimum_warning_web",
  WEB_FOREIGN_ENTITY_ACH_DISABLED: "foreign_entity_ach_disabled",
  WEB_USE_GEMINI_SUBDOMAIN_SARDINE: "web_use_gemini_subdomain_sardine",
  WEB_WORLDPAY_FLOW_REVAMP: "web_worldpay_flow_revamp",
  IS_WEB_DEBIT_CARD_3DS_ENABLED: "is_web_debit_card_3ds_enabled",
  WEB_FINANCIAL_PROMOTIONS_LEAVE_JOURNEY: "web_financial_promotions_leave_journey",
  WEB_SPLIT_DERIVATIVES_ACCOUNT_CREATION: "web_split_derivatives_account_creation",
  WEB_BYPASS_MAS_RISK_ASSESSMENT: "web_bypass_mas_risk_assessment",
  WEB_MARKET_STATS: "web_market_stats",
  WEB_TRADING_ASSET_IS_RISKY_CONTENT: "mobile_trading_asset_is_risky_content", // Intentionally using `mobile_` flag here.
  WEB_ONFIDO_MOTION_ONBOARDING: "web_onboarding_onfido_motion",
  TREX_1060_WEB_BLOTTER_CUSTOMIZATION: "trex-1060_web_blotter_customization",
  TREX_1505_BLOTTER_DETAILS: "trex_1505_blotter_details",
  ASSET_MIGRATION_BANNER_LIST: "asset_migration_banner_list",
  DBS_SG_INTEGRATION_ENABLED: "dbs_fast_payment_method",
  DBS_FAST_BANNER_ENABLED: "dbs_fast_transfers_banner",
  TRANSFER_LIMITS_TIMINGS_ENABLED: "web_transfer_limits_and_timings",
  WEB_OVERRIDING_TRADING_PAIRS: "overriding-trading-pairs",
  WEB_TRADING_VIEW_TRADING_PLATFORM: "trading_view_trading_platform",
  NEW_CANCEL_CLEARING_FLOW: "new_cancel_clearing_flow",
  WEB_HIDE_PAYMENT_METHODS_FOR_MOONBASE_LAUNCH: "web_hide_payment_methods_for_moonbase_launch",
  WEB_HIDE_CURRENCIES_FOR_MOONBASE_LAUNCH: "web_hide_currencies_for_moonbase_launch",
  WEB_HIDE_CURRENCIES_FOR_MOONBASE_LAUNCH_ACTIVE_TRADER: "web_hide_currencies_for_moonbase_launch_active_trader",
  WEB_DISABLE_STEP_UP_VERIFY_SELFIE_AFTER_TIME_LIMIT_EXCEEDED:
    "web-disable-step-up-verify-selfie-after-time-limit-exceeded",
  WEB_MAINTENANCE_STATUS: "web_maintenance_status",
  WEB_BASIC_PLUS_UPGRADE_BANNER: "web_basic_plus_upgrade_banner",
  ADDRESS_BOOK: "address_book",
  SHOW_GAS_MSG_STAKE: "show_gas_msg_stake",
  UNSUPPORTED_ASSETS: "unsupported_assets",
  WEB_ENABLE_DIRECT_REFERRAL: "web_enable_direct_referrals",
  WEB_BASIC_PLUS_DEPOSIT_CRYPTO: "web_basic_plus_deposit_crypto",
  GUSD_SCHEDULED_RECON_MESSAGING: "gusd_scheduled_recon_messaging",
  GUSD_DISABLE_DURING_RECON: "gusd_disable_during_recon",
  DISPLAY_INCOMING_DEPOSITS: "display_incoming_deposits",
  DISPLAY_PENDING_ONCHAIN_TRANSFERS_TX_HISTORY: "display_pending_onchain_transfers_tx_history",
  WEB_SHOW_AUTHY_ONBOARDING: "web_show_authy_onboarding",
  WEBSOCKET_ORDERS: "websocket-orders",
  WEB_ADD_ADDRESS_OVERHAUL: "web_add_address_overhaul",
} as const;

// for use with /exp/variation api, experiment key may be different than ff key
export const OPTIMIZELY_EXPERIMENT_FLAGS = {
  PasskeyOnboardingExperiment: "onboarding_passkey_creation_experiment",
} as const;
export const OptimizelyExperimentResponseType = {
  CONTROL: "control",
  TREATMENT: "treatment",
} as const;
export type OptimizelyExperimentType =
  (typeof OptimizelyExperimentResponseType)[keyof typeof OptimizelyExperimentResponseType];
interface FeatureFlagVariables {
  WEB_POLLING_INTERVALS: {
    RETAIL: string;
    ACTIVE_TRADER: string;
  };
  SINGAPORE_MAS_TRANSFER_CONTROL_WEB: {
    PROOF_OF_CONTROL_SUCCESS_MESSAGE_ENABLED: string;
    MAS_DISCLOSURE_ENABLED: string;
  };
  NETHERLANDS_OFFBOARD: {
    RECURRING_ORDERS_DISABLED: string;
  };
  WEB_UK_OUTBOUND_TRAVEL_RULE: {
    UNSUPPORTED_TOKENS: string;
  };
  WEB_EARN_DISTRIBUTIONS_HOMEPAGE_BANNER: {
    TITLE: string;
    MESSAGE: string;
  };
  WEB_OVERRIDING_TRADING_PAIRS: {
    PAIRS: string;
  };
  WEB_MAINTENANCE_STATUS: {
    POLLING_INTERVAL: string;
  };
  UNSUPPORTED_ASSETS: {
    DELISTED_ASSETS: string;
    SUSPENDED_PAIRS: string;
  };
}

export const FEATURE_FLAG_VARIABLES: FeatureFlagVariables = {
  WEB_POLLING_INTERVALS: {
    RETAIL: "retail",
    ACTIVE_TRADER: "active_trader",
  },
  SINGAPORE_MAS_TRANSFER_CONTROL_WEB: {
    PROOF_OF_CONTROL_SUCCESS_MESSAGE_ENABLED: "proofOfControlSuccessMessageEnabled",
    MAS_DISCLOSURE_ENABLED: "masDisclosureEnabled",
  },
  NETHERLANDS_OFFBOARD: {
    RECURRING_ORDERS_DISABLED: "recurringOrdersDisabled",
  },
  WEB_UK_OUTBOUND_TRAVEL_RULE: {
    UNSUPPORTED_TOKENS: "unsupportedWithdrawalAssets",
  },
  WEB_EARN_DISTRIBUTIONS_HOMEPAGE_BANNER: {
    TITLE: "title",
    MESSAGE: "message",
  },
  WEB_OVERRIDING_TRADING_PAIRS: {
    PAIRS: "pairs",
  },
  WEB_MAINTENANCE_STATUS: {
    POLLING_INTERVAL: "polling_interval",
  },
  UNSUPPORTED_ASSETS: {
    DELISTED_ASSETS: "delistedAssets",
    SUSPENDED_PAIRS: "suspendedPairs",
  },
};
